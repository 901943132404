.contact{
    padding: 3rem 0px;
    width: 100%;
    display: flex;
}
.contact-left{
    width: 90%;
    padding-top: 4rem;
    padding-left: 3rem;
    padding-right:1rem;
}
.contact-left form{
    width: 100%;
}
.contact-left form .form-1{
    margin-top: 23px;
    width: 100%;
    margin-bottom:12px;
}
.contact-left form .form-1 input, .contact-left form .form-1 select{
    width: 100%;
    padding: 0.45rem 0.75rem;
    color: #000000;
    font-size: 0.5rem;
    border:1px solid #E0E0E0;
    border-radius: 3px;
}
.form-1 input::placeholder{
    color: #000000;
    font-size: 0.5rem;
}
.form-1 input:focus, .form-1 select:focus{
    outline: #2452d160;
}
.contact-right{
    width: 10%;
    background-color: #2453D1;
    border-radius: 40px 0px 0px 40px;
}
.contact-head{
    font-size: 2rem;
}
.contact-head span{
    color: #2453D1;
}
.contact-body{
    font-size: 9px;
}
.send{
    width: 100%;
    padding: 0.5rem;
    border: none;
    background-color: #2453D1;
    color: white;
    font-size: 0.6rem;
    font-weight: 700;
    font-family: "Poppins";
    border-radius: 3px;
}
.contact-us{
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contact-method{
    display: flex;
    align-items: center;
    gap: 10px;
}
.contact-details h4{
    font-size: 0.5rem;
    font-weight: 800;
    color: #000000;
}
.contact-details p{
    font-size: 0.4rem;
    font-weight: 600;
    color: #DD5471;
}
.map{
    margin-top: 24px;
    border: 0px;
    width: 100%;
    height: 120px;
    border-radius: 5px;
}
@media (min-width:1024px){
    .contact{
        padding: 3rem 7rem;
        width: 100%;
        display: flex;
    }
    .contact-left{
        position: relative;
        width: 65%;
        padding: 3rem;
        display: flex;
        align-items: center;
        gap: 33px;
        border-radius: 40px 0px 0px 40px;
        /* padding-top: 0rem;
        padding-left: 3rem;
        padding-right:1rem; */
        box-shadow: 0px 116.56px 165.33px -66.13px rgba(25, 58, 75,0.2);
        -webkit-box-shadow: 0px 116.56px 165.33px -66.13px rgba(25, 58, 75,0.2);
        -moz-box-shadow: 0px 116.56px 165.33px -66.13px rgba(25, 58, 75,0.2);
    }
    .contact-right{
        width: 35%;
        background-color: #2453D1;
        border-radius: 0px 40px 40px 0px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
    }
    .contact-left form .form-1 input, .contact-left form .form-1 select{
        padding: 0.625rem 1rem;
    }
    .contact-left-item{
        width: 80%;
    }
    .contact-map{
        width: 20px;
    }
    .map{
        margin-top: 0px;
        border: 0px;
        width: 250px;
        height: 350px;
        border-radius: 5px;
    }
}
@media (min-width:1440px){
    .contact{
        padding: 3rem 12rem;
        width: 100%;
        display: flex;
    }
    .contact-left{
        position: relative;
        width: 70%;
        padding: 7rem;
        gap: 33px;
    }
    .contact-head{
        font-size: 3rem;
    }
    .contact-head span{
        color: #2453D1;
    }
    .contact-body{
        font-size: 0.9rem;
    }
    .contact-left form .form-1 input, .contact-left form .form-1 select{
        width: 100%;
        padding: 0.45rem 0.75rem;
        color: #000000;
        font-size: 0.6875rem;
        border:1px solid #E0E0E0;
        border-radius: 3px;
    }
    .form-1 input::placeholder{
        color: #000000;
        font-size: 0.6875rem;
    }
    .contact-right{
        width: 30%;
    }
    .contact-left form .form-1 input, .contact-left form .form-1 select{
        padding: 0.625rem 1rem;
    }
    .contact-left-item{
        width: 80%;
    }
    .contact-details h4{
        font-size: 0.625rem;
    }
    .contact-details p{
        font-size: 0.625rem;
    }
    .contact-map{
        width: 20px;
    }
    .map{
        margin-top: 0px;
        border: 0px;
        width: 300px;
        height: 400px;
        border-radius: 5px;
    }
}