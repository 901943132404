.business{
    margin-top: 64px;
    padding: 4rem 2rem;
}

.business-body{
    margin-top: 54px;
    border-radius: 13px;
    background-color: #e1e7f8b5;
    padding: 3rem 1.5rem;
}
.tool{
    margin-bottom: 62px;
}
.tool-head{
    font-size: 1.24rem;
    color: #000000;
}
.tool-text{
    margin-top: 10px;
    margin-bottom: 29px;
    font-size: 0.75rem;
    color: #000000; 
}
.tools-benefit{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 27px;
}
.tools-icon{
    border-radius: 6px;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tools-benefit-text{
    width: calc(100% - 50px);
}
.tools-benefit-text h3{
    font-size: 1.2rem;
    color: #000000;
}
.tools-benefit-text p{
    margin-top: 10px;
    font-size: 0.75rem;
}
.tools-image{
    margin-top: 59px;
    padding: 6rem 1rem; 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(80, 118, 218, 0.1);
}
.tools-image-2{
    padding: 3rem 1rem; 
    background-color: transparent;
}
.tools-image img{
    width: 98%;
}
.card-1{
    position: absolute;
    top: 1.6rem;
    left: 0px;
    width: 165px;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 5px;
}
.card-2{
    position: absolute;
    bottom: 2rem;
    right: -10px;
    position: absolute;
    width: 165px;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 5px;
}
.card-3{
    position: absolute;
    top: 3rem;
    right: 0px;
    width: 165px;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 5px;
}
.card-4{
    position: absolute;
    bottom: 1rem;
    left: -10px;
    position: absolute;
    width: 165px;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 5px;
}


.card-1 h3, .card-2 h3, .card-3 h3, .card-4 h3{
    font-size: 0.5625rem;
    color: #5076DA;
}
.card-1 p, .card-2 p, .card-3 p, .card-4 p{
    font-size: 0.375rem;
    color: #000000;
    line-height: 12px;
}
@media (min-width: 1024px) {
    .business-body{
        padding: 4rem 3.5rem;
    }
    .tool{
        display: flex;
        gap: 100px;
        margin-bottom: 150px;
    }
    .tool-2{
        flex-direction: row-reverse;
        align-items: center;
    }
    .tool-details{
        width: 50%;
    }
    .tools-image{
        margin-top: 0px;
        width: 50%;
    }
    .tool-head{
        font-size: 1.6rem;
    }
    .tools-icon{
        width: 70px;
        height: 70px;
    }
    .tools-icon svg{
        font-size: 1.5rem;
    }
    .tool-text{
        font-size: 1rem;
    }
    .tools-benefit-text h3{
        font-size: 1.5rem;
    }
    .tools-benefit-text p{
        font-size: 1rem;
    }
    .card-1{
        top: 25.6px;
        left: -40px;
        width: 280px;
        padding: 0.5rem 1rem;
    }
    .card-2{
        bottom: 80px;
        right: -30px;
        width: 280px;
        padding: 0.5rem 1rem;
    }
    .card-3{
        top: 40px;
        right: -70px;
        width: 280px;
        padding: 0.5rem 1rem;
    }
    .card-4{
        bottom: 16px;
        left: -10px;
        width: 280px;
        padding: 0.5rem 1rem;
    }
    .card-1 h3, .card-2 h3, .card-3 h3, .card-4 h3{
        font-size: 0.8rem;
        color: #5076DA;
    }
    .card-1 p, .card-2 p, .card-3 p, .card-4 p{
        font-size: 0.7rem;
        color: #000000;
        line-height: 12px;
    }
}
@media (min-width: 1280px) {
    .business{
        padding: 4rem 5rem;
    }
    .tool{
        display: flex;
        gap: 157px;
    }
    .tool-details{
        width: 50%;
    }
    .tools-image{
        width: 50%;
    }
    .tools-image img{
        width: 90%;
    }
    .tool-head{
        font-size: 2rem;
        color: #000000;
    }
    .tool-text{
        margin-top: 10px;
        margin-bottom: 29px;
        font-size: 1.3rem;
        color: #000000; 
    }
    .tools-benefit-text h3{
        font-size: 2rem;
        color: #000000;
    }
    .tools-benefit-text p{
        margin-top: 10px;
        font-size: 1.3rem;
    }
    .card-1{
        top: 40px;
        left: -50px;
        width: 354px;
        padding: 0.5rem 1.5rem;
    }
    .card-2{
        bottom: 80px;
        right: -30px;
        width: 354px;
        padding: 0.5rem 1.5rem;
    }
    .card-3{
        top: 40px;
        right: -70px;
        width: 354px;
        padding: 0.5rem 1.5rem;
    }
    .card-4{
        bottom: 16px;
        left: -10px;
        width: 354px;
        padding: 0.5rem 1.5rem;
    }
    .card-1 h3, .card-2 h3, .card-3 h3, .card-4 h3{
        font-size: 1.06rem;
        color: #5076DA;
    }
    .card-1 p, .card-2 p, .card-3 p, .card-4 p{
        font-size: 0.75rem;
        color: #000000;
        line-height: 22px;
    }
}