.navbar{
    position: relative;
    padding: 1.9rem 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(38, 85, 209, 0.1);
}
.navbar-mobile{
    transform: translateX(-110%);
    transition: all 0.4s;
    z-index: 100;
}
.navbar-mobile-open{
    transform: translateX(0%);
}
.navbar-logo p{
    font-size: 1.25rem;
    font-weight: 500;
    color: rgba(36, 83, 209, 0.8);
}
.navbar-nav{
    display: none;
}
.navbar-button{
    display: none;
}
.navbar-mobile{
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 100%;
    height: 150vh;
    background-color: rgba(36, 82, 209, 0.95);
    padding: 3rem;
}
.nav-close{
    display: flex;
    justify-content: right;
    margin-bottom: 50px;
}   
.nav-close svg{
    color: white;
    font-size: 20px;
}
.navbar-mobile nav{
    list-style: none;
}
.navbar-mobile nav li{
    margin-bottom: 40px;
    font-size: 1.5rem;
    font-weight: 600;
    color: white;
    cursor: pointer;
}
.navbar-mobile-button{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
}
.navbar-mobile-button a{
    width: 100%;
}
.login-mobile-button, .register-mobile-button{
    width: 100%;
    padding: 1.4rem 3rem;
    background-color: white;
    font-family: "Poppins";
    font-size: 1.2rem;
    font-weight: 600;
    color: rgba(36, 83, 209, 0.9);
    border: none;
    border-radius: 8px;
}
@media (min-width: 1024px) {
    .navbar{
        padding: 1.9rem 3rem;
    }
    .navbar-logo p{
        font-size: 1.5rem;
        font-weight: 500;
        color: rgba(36, 83, 209, 0.8);   
    }
    .navbar-mobile{
        display: none;
    }
    .navbar-hamburger{
        display: none;
    }
    .navbar-nav{
        display: block;
    }
    .navbar-button{
        display: block;
    }
    .navbar-nav nav{
        list-style: none;
        display: flex;
        align-items: center;
        gap: 55px;
    }
    .navbar-nav nav li{
        font-size: 18px;
        font-weight: 500;
        color: #071827;
        cursor: pointer;
    }
    .navbar-button{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .login-button{
        padding: 1rem 2rem;
        background-color: transparent;
        font-family: "Poppins";
        font-size: 0.9rem;
        font-weight: 500;
        color: rgba(36, 83, 209, 0.9);
        border: 1px solid rgba(36, 83, 209, 0.8);
        border-radius: 8px;
    }
    .register-button{
        padding: 1rem 2rem;
        background-color: rgba(36, 83, 209, 0.8);
        font-family: "Poppins";
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        border: 1px solid rgba(36, 83, 209, 0.8);
        border-radius: 8px;
    }
}
@media (min-width: 1280px) {
    .navbar{
        padding: 1.9rem 6rem;
    }
    .navbar-logo p{
        font-size: 2.2rem;
    }
    .navbar-nav nav li{
        font-size: 20px;
    }
    .login-button{
        padding: 1rem 3.2rem;
        font-size: 1.125rem;
    }
    .register-button{
        padding: 1rem 3.2rem;
        font-size: 1.125rem;
    }
}