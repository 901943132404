.home{
    position: absolute;
    overflow-x: hidden;
}
.blob2{
    z-index: -2;
    position: absolute;
    top: -19rem;
    left: -30rem;
    width: 100%;
    color: #1c37cf0a;
}