.header{
    padding: 3rem 2rem;
    background-color: rgba(38, 85, 209, 0.1);

}
.header-empower{
    font-size: 2rem;
    font-weight: 600;
    color: #058B42;
}
.header-business{
    width: 100%;
    font-size: 1.5rem;
    font-weight: 500;
    color: #000000f3;
    line-height: 28.9px;
    letter-spacing: -3%;
}
.header-content p{
    margin-top: 9.85px;
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(7, 24, 39, 0.6);
    line-height: 25px;
    letter-spacing: 0%;
}
.header-content p span{
    color: #071827;
    font-weight: 600;
}
.header-button{
    margin-top: 37.76px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.header-button button{
    background-color: transparent;
    border: 1px solid #B11226;
    color: #B11226;
    font-size: 0.6875rem;
    font-weight: 600;
    padding: 0.65rem 1.0625rem;
    border-radius: 5.2px;
}
.header-button p{
    margin: 0px !important;
    color: #1E1E1E !important;
    
}
.header-image{
    width: 100%;
    margin-top: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-image img{
    width:89%;
}
@media (min-width: 1024px) {
    .header{
        padding: 1rem 6rem 10rem 6rem;
        background-color: rgba(38, 85, 209, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 4;
    }
    .header-content{
        width: 60%;
    }
    .header-image{
        width: 40%;
    }
    .header-empower{
        font-size: 3rem;
    }
    .header-business{
        font-size: 2.3rem;
        line-height: 38.4px;
    }
    .header-content p{
        margin-top: 9.85px;
        font-size: 1rem;
        line-height: 20.8px;
    }
    .header-button{
        z-index: 4;
    }
    .header-button button{
        background-color: transparent;
        border: 1px solid #B11226;
        color: #B11226;
        font-size: 1rem;
        font-weight: 600;
        padding: 0.65rem 1.0625rem;
        border-radius: 5.2px; 
    }
    .header-image{
        width: 45%;
        margin-top: 0px;
    }
    .header-image img{
        width:100%;
    }
}
@media (min-width: 1280px) {
    .header{
        padding: 1rem 6rem 10rem 6rem;
    }
    .header-empower{
        font-size: 4rem;
    }
    .header-business{
        font-size: 3rem;
        line-height: 58.4px;
    }
    .header-content p{
        font-size: 1.25rem;
        line-height: 25.8px;
    }
}