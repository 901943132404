.about{
    padding: 3rem 2rem;
}
.about-body{
    margin-top: 70px;
    border-radius:20px;
    padding: 1.6rem 1.25rem;
    background-color: rgba(36, 83, 209, 0.8);
}
.about-text{
    margin-bottom: 20px;
    font-size: 0.875rem;
    font-weight: 400;
    color: white;
    text-align: justify;
    line-height: 27px;
}
.credio-images{
    width: 100%;
    display: flex;
    justify-content: center;
}
.credio-images img{
    width: 100%;
    margin-bottom: -6rem;
}
@media (min-width: 1024px) {
    .about-text{
        font-size: 1rem;
    }
    .about-body{
        padding: 4rem 5rem;
    }
    .credio-images img{
        width: 75%;
        margin-bottom: -8rem;
    }
}
@media (min-width: 1280px) {
    .about{
        padding: 3rem 5rem;
    }
    .about-text{
        font-size: 1.5rem;
    }
}