.feature{
    padding: 3.12rem 1.5rem;
}
.feature-head p{
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: -3%;
}
.feature-head p span{
    color: white;
    padding: 0.2rem;
    background-color: rgba(36, 83, 209, 0.9);
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: -3%;
}
.feature-body{
    margin-top: 56px;
}
.side-image-con{
    display: none;
}
.mobile-friendly{
    padding: 2rem 1.5rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    background: url("../../Assets/topology.png") rgba(36, 83, 209, 0.8);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.inventory-image{
    width: 100%;
    margin-top: 70px;
    margin-bottom: -2.4rem;
    display: flex;
    justify-content: center;
}
.admin-image{
    margin-bottom: 0rem ;
}
.inventory-image img{
    width: 100%;
}
.mobile-text h3{
    font-size: 1.5rem;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 30px;
}
.mobile-text p{
    margin-top: 10px;
    font-size: 0.875rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
}
.body-second{
    margin-top: 51px;
}
@media (min-width: 1024px) {
    .body-first{
        display: flex;
        gap: 37px;
    }
    .feature-head p{
        text-align: center;
        font-size: 2.5rem;
        font-weight: 500;
        letter-spacing: -3%;
    }
    .feature-head p span{
        color: white;
        padding: 0.2rem;
        background-color: rgba(36, 83, 209, 0.9);
        border-radius: 3px;
        font-weight: 500;
        letter-spacing: -3%;
    }
    .side-image-con{
        width: 30%;
        height: 280px;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        border-radius: 12px;
        /* background-color: rgba(36, 83, 209, 0.3); */
        background-image: url("../../Assets/topology.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .side-image-con-second{
        padding-top: 0;
        padding-bottom: 2rem;
    }
    .side-image-con img{
        width: 60%;
    }
    .mobile-friendly{
        width: 70%;
        height: 280px;
        padding: 0rem 1.5rem;
        display: flex;
        gap: 52px;
        flex-direction: row;
        align-items: center;
    }
    .inventory-image{
        width: 45%;
        height: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
        align-items: end;
    }
    .mobile-text{
        width: 55%;
    }
    .admin-image{
        margin-bottom: 0rem;
        align-items: center;
    }
    .inventory-image img{
        width: 100%;
    }
    .mobile-text p{
        margin-top: 10px;
        font-size: 0.75rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
    }
}
@media (min-width: 1280px){
    .feature{
        padding: 3.12rem 5rem;
    }
    .inventory-image img{
        width: 80%;
    }
    .side-image-con img{
        width: 50%;
    }
}
@media (min-width: 1440px){
    .mobile-friendly{
        height: 354px;
    }
    .side-image-con{
        height: 354px;
    }
    .inventory-image img{
        width: 70%;
    }
    .side-image-con img{
        width: 50%;
    }
    .mobile-text p{
        font-size: 1rem;
    }
}